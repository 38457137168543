import Vue from 'vue'
import VueRouter from 'vue-router'
import Inscricao from '../views/Inscricao.vue'
import Sermacon from '../views/Sermacon.vue'
import ConfirmaInscricao from '../views/ConfirmaInscricao.vue'
import EscolherApartamento from '../views/EscolherApartamento.vue'
import PagamentoLogin from '../views/PagamentoLogin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'sermacon',
    component: Sermacon
  },
  {
    path: '/inscricao',
    name: 'inscricao',
    component: Inscricao
  },
  {
    path: '/confirma-inscricao/:email',
    name: 'confirma-inscricao',
    component: ConfirmaInscricao
  },
  {
    path: '/escolher-apartamento',
    name: 'escolher-apartamento',
    component: EscolherApartamento
  },
  {
    path:'/pagamento-login/:email?',
    name:'pagamento-login',
    component: PagamentoLogin
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  }
]
const base_local = process.env.BASE_URL; //em producao roda base local também, até descobrir uma forma de colocar a base para funcionar com subdominio.
// const base_producao = "sermacon2024";

const router = new VueRouter({
  mode: 'history',
  base: base_local,
  routes
})

export default router
