<template>
    <div class="confirma inscricao">
        <div class="content">
            <div class="imagem">
                <img src="../assets/LOGO-SERMACCON-2024.png" alt="logo-sermacon" class="img-responsive" width="40%" />
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <div class="alert alert-warning">
                        <h1>
                            Inscrição efetuada com sucesso.
                        </h1>
                    </div>
                </div>
            </div>

            <router-link :to="{ name: 'pagamento-login', params: { email: this.$route.params.email }}" class="btn btn-lg btn-success">
                💳 Realizar pagamento com cartão de crédito
            </router-link>

            <div class="row alerta-conclusao">
                <!-- informacoes_pos_inscricao -->
                <div class="informacoes_pos_inscricao">
                    <strong>Você receberá uma cópia do seu pedido de reserva!</strong>
                    <br />
                    <br />
                    Ela será confirmada somente após a confirmação do pagamento!<br />
                    Verifique o comprovante de reserva na sua “CAIXA DE ENTRADA” ou na “CAIXA DE SPAM”!<br />
                    Enviamos para o e-mail abaixo: 
                    {{ this.$route.params.email }}
                    <br />

                    <br />
                </div>

            </div>


            <div class="new-subscription">
                <router-link :to="{ name: 'inscricao' }" class="btn  btn-lg btn-info">
                    &#9998; FAZER NOVA INSCRIÇÃO
                </router-link>
            </div>


        </div>
    </div>
</template>

<style scoped>
.confirma.inscricao .content .imagem img {
    margin: 0 auto;
}

.new-subscription {
    margin-block: 50px;
    text-align: center;
    margin: 8px auto;
}

.informacoes_pos_inscricao {
    background-color: #e03d3d;
    padding: 18px;
    font-size: 18px;
    color: #ffffff;
    width: 80%;
    border-radius: 5px;
    margin: 1rem auto;
}
</style>

<script>
export default {
    name: "confirmaInscricao",
    data: function () {
        return {

        };
    },
};
</script>