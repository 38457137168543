<template>
	<div class="home inscricao">
		<div class="content">
			<div class="imagem">
				<img src="../assets/LOGO-SERMACCON-2024.png" alt class="img-responsive" width="40%" />
			</div>

			<div class="row">
				<div class="col-sm-12">
					<div class="alert alert-warning">
						<strong>Data: </strong>30, 31 DE AGOSTO E 01 DE SETEMBRO DE 2024<br />
						<strong>Local: </strong> SESC Aracruz <br />
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-12">
					<div class="box-pagamento-cartao">
						<h5>Já fez a inscrição e quer <strong>pagar com cartão de crédito?</strong></h5>
						<router-link :to="{ name: 'pagamento-login' }" class="btn btn-success">Clique aqui</router-link>
					</div>
				</div>
			</div>
			<!--
			<div class="row">
				<div class="field col-sm-4">
					<label for>É Maçom?</label>
					<select v-model="cadastro_macon" class="form-control">
						<option :value="true">Sim</option>
						<option :value="false">Não</option>
					</select>
				</div>
			</div>
-->
			<div class="row">
				<div class="field col-sm-6" v-for="(campo, index) in campos">
					<label for>
						{{ campo.label }}
						<span v-if="is_mandatory(campo.id)">*</span>
					</label>
					<input v-if="(campo.mask.length == 0) && !campo.combo" type="text" :name="campo.id" :id="campo.id"
						v-model="campo.valor" class="form-control" :required="is_mandatory(campo.id)"
						v-on:focus="search_field_open(campo)" v-on:focusout="search_field_close(campo)"
						autocomplete="off" @input="functionsInput(campo, $event)" />
					<the-mask v-if="(campo.mask.length > 0) && !campo.combo" :mask="campo.mask" v-model="campo.valor"
						class="form-control" :required="is_mandatory(campo.id)"
						@input="functionsInput(campo, $event)" />
					<select v-if="campo.combo" v-model="campo.valor" class="form-control"
						:required="is_mandatory(campo.id)">
						<option value>-- Selecione --</option>
						<option v-for="option in campo.options" :value="option">{{ option }}</option>
					</select>

					<div v-if="campo.search_field && campo.search_field_active" class="search_field">
						<ul>
							<li v-if="filter_loja(campo.valor, loja)" v-on:click="campo.valor = loja"
								v-for="loja in campo.options">
								{{ loja }}
							</li>
						</ul>
					</div>
				</div>
				<!-- <div class="col-sm-12"> 
					<div class="botao">
						<button class="btn btn-success pull-right">Enviar</button>
					</div>
				</div>-->
			</div>
			<div class="row">
				<div class="col-sm-12">
					<div class="tabela-precolet dados = this.valor_total_number;">
						<div class="title">Tabela de Preços</div>
						<table class="table let dados = this.valor_total_number;table-condensed table-striped">
							<thead>
								<tr>
									<th>Item</th>
									<th>Valor</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Individual</td>
									<td>R$ 1.250,00</td>
								</tr>
								<tr>
									<td>Duplo</td>
									<td>R$ 1.600,00</td>
								</tr>
								<tr>
									<td>Cama Extra Adulto</td>
									<td>R$ 800,00</td>
								</tr>
								<tr>
									<td>Criança de 6 a 10 anos (sendo a pessoa extra)</td>
									<td>R$ 480,00</td>
								</tr>
								<tr>
									<td>Crianças até 5 anos</td>
									<td>ISENTO</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div class="quartos">
					<!--
					<div class="title">Mapa de Acesso <br>
						<a href="../assets/Mapa-de-CTSLPF.pdf" class="btn btn-info" target="_blank">CLIQUE AQUI E CONHEÇA O LOCAL</a>
					</div>
					<img src="../assets/mapa-praia-formosa.jpg" alt="Mapa do SESC" class="mapa-sesc" width="70%" />
-->
					<div class="title">Quartos disponíveis</div>
					<table class="table let dados = this.valor_total_number;table-condensed table-striped">
						<thead>
							<tr>
								<th>Bloco</th>
								<th>Casal</th>
								<th>Solteiro (Double)</th>
								<th>Casal PNE</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="bloco in blocos">
								<td>{{ bloco.nome }}</td>
								<td>{{ bloco.casal }}</td>
								<td>{{ bloco.solteiro_double }}</td>
								<td>{{ bloco.casal_pne_double }}</td>
							</tr>
						</tbody>
					</table>
					<div class="title">Escolha os quartos para incluir o inscrito e os acompanhantes.</div>
					<div class="aviso-quarto-inscrito" style="color:red"><b>Atenção:Você deve acomodar-se em um dos
							quartos, ou seja, o CPF usado anteriormente acima deve estar em um dos quartos.</b></div>

					<div class="quartos-content">
						<div class="item" v-for="(quarto, index_quarto) in quartos_selected">
							<div class="remove-quarto">
								<button v-on:click="remove_quarto(index_quarto)" class="btn btn-danger">Remover
									Quarto</button>
							</div>
							<div class="info">
								<div class="title">{{ quarto.title }}</div>
								<div class="descricao">{{ quarto.desc }}</div>
							</div>
							<div class="escolhe_bloco">
								<div class="row">
									<div class="col-sm-4 col-xs-6">
										<div class="field">
											<label for>Escolha o bloco</label>
											<select v-model="quarto.bloco_escolhido" class="form-control">
												<option value>-- Selecione --</option>
												<option v-for="bloco_escolher in get_blocos(quarto.id)"
													:value="bloco_escolher.id">{{ bloco_escolher.nome }}</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							<div class="pessoas flex-wrap">
								<div class="title">Informe as pessoas neste quarto <span style="color:red;"><i>com as
											idades (hospede principal, esposa, etc...):</i></span></div>
								<div class="pessoas-content">
									<div :class="{ pessoa: true, 'cama-extra': (index >= quarto.cama_principal) }"
										v-for="(pessoa, index) in quarto.pessoas">
										<div class="field">
											<label for>Nome</label>
											<!--<input v-if="quartos_selected.length == 1"  type="text" v-model="pessoa.nome" @input="functionsInputUpper($event)" class="form-control upper" /> -->
											<input type="text" v-model="pessoa.nome"
												@input="functionsInputUpper($event)" class="form-control upper" />
										</div>
										<div class="field">
											<label for>CPF <span style="color:red;">(Obrigatório)</span> </label>
											<the-mask :mask="'###.###.###-##'" v-model="pessoa.cpf"
												class="form-control" />
											<!-- <input v-mask="'###.###.###-##'" type="text" v-model="pessoa.cpf" class="form-control" /> -->
										</div>
										<div class="field">
											<label for>Idade</label>
											<input type="text" v-mask="'###'" v-model="pessoa.idade"
												class="form-control" />
										</div>
										<div class="field">
											<label for>Parentesco</label>
											<!-- <input type="text" v-model="pessoa.parentesco" class="form-control" /> -->
											<select class="form-control" v-model="pessoa.parentesco" name id>
												<option selected disabled value>Selecione</option>
												<option value="EU">HÓSPEDE PRINCIPAL</option>
												<option value="ESPOSA">ESPOSA</option>
												<option value="FILHO">FILHO(A)</option>
												<option value="SOBRINHO">SOBRINHO(A)</option>
												<option value="MAE">MÃE</option>
												<option value="PAI">PAI</option>
												<option value="AVO">AVÔ/AVÓ</option>
												<option value="OUTRO">OUTRO</option>
											</select>
										</div>
										<div v-if="(index < quarto.cama_principal)" class="field">
											<label for>Tamanho da Camisa</label>
											<select class="form-control" v-model="pessoa.tamanho_camisa" name id>
												<option selected disabled value>Selecione o tamanho</option>
												<option value="P">P</option>
												<option value="M">M</option>
												<option value="G">G</option>
												<option value="GG">GG</option>
												<option value="XG">XG</option>
											</select>
										</div>
										<div class="field">
											<button v-on:click="remove_pessoa(quarto.pessoas, index)"
												class="btn btn-danger">X</button>
										</div>
									</div>
									<div class="btn-content">
										<button
											v-if="quarto.pessoas.length < (quarto.cama_principal + quarto.cama_extra)"
											v-on:click="adiciona_pessoa(quarto.pessoas)"
											class="btn btn-success">Adicionar
											Pessoa</button>
									</div>
								</div>
							</div>
							<!-- <div class="subtotal">
								<div class="title">Subtotal</div>
								<div class="valor">{{quarto.subtotal}}</div>
							</div>-->
						</div>
					</div>
					<div class="col-sm-12">

						<div v-if="quartos_selected.length > 0" class="title">Deseja adquirir mais um quarto?</div>

						<div class="quarto-select">
							<select class="form-control" v-model="quarto_selected" name id>
								<option selected disabled value>-- Selecione um quarto e clique em adicionar</option>
								<option v-for="(quarto, index) in quartos" :value="index">{{ quarto.title.toUpperCase()
									}}
									({{ quarto.desc }})</option>
							</select>
							<button v-on:click="adiciona_quarto();" class="btn btn-success pull-right">Adicionar
								Quarto</button>
						</div>

					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-12">
					<br />
					<br />
					<div v-if="alert_show" class="alert" :class="'alert-' + alert_status">{{ alert_message }}</div>
				</div>
			</div>
			<div class="row alerta-conclusao">
				<!-- informacoes_pos_inscricao -->
				<div v-if="informacoes_pos_inscricao != false" class="informacoes_pos_inscricao">
					<strong>Você receberá uma cópia do seu pedido de reserva!</strong>
					<br />
					<br />
					Ela será confirmada somente após a confirmação do pagamento!<br />
					Verifique o comprovante de reserva na sua “CAIXA DE ENTRADA” ou na “CAIXA DE SPAn”!<br />
					Enviamos para o e-mail abaixo:
					<br />
					<b>{{ informacoes_pos_inscricao }}</b>

					<br />
				</div>

			</div>
			<div class="dados_pagamento">
				<h2>Informações para pagamento</h2>
				<hr>
				<p>
					<span><strong>Cartão de crédito: </strong></span>
					Após selecionar o botão verde <b>“CONFIRMAR INSCRIÇÃO”</b>, será exibido uma tela com a opção para
					pagamento em cartão de crédito!

					Você também receberá uma cópia da sua inscrição por e-mail, contendo o mesmo, também um link para
					que você possa efetuar o pagamento via cartão de crédito!
				</p>
				<p>
					<span><strong> Chave PIX: </strong></span>sermacon2024@gmail.com
				</p>
				<div>
					<p>
						<span><strong>Transferência bancaria:</strong></span>
					</p>
					<ul>
						<li>Banco: 104 - Caixa Econômica Federal</li>
						<li>Agência: 0880</li>
						<li>Conta: 00001789-2</li>
						<li>Operação: 003</li>
					</ul>
				</div>
				<p>
					<span><strong>Dinheiro:</strong></span> Realizar pagamento diretamente na sede do GOB-ES
				</p>
				<div>
					<p>
						<span><strong>Cheque: </strong></span>Realizar pagamento diretamente na sede do GOB-ES
					</p>
					<p>Você poderá realizar o pagamento em até 3X no cheque, tendo o último vencimento até dia
						15/08/2024
					</p>
				</div>
				<hr>
				<div>
					<h3 style="color:red">Observação!!! <br> (Envie o comprovante de pagamento para o e-mail
						sermacon2024@gmail.com)</h3>
					<p>Sua inscrição será confirmada somente após o envio dos comprovantes de pagamento, e confirmação
						do recebimento pelo
						GOB-ES;

						Realizando sua inscrição utilizando um dos 4 meios acima, você tem até 10 dias corridos para
						enviar
						os respectivos comprovantes para o GOB-ES;

						Para quem utilizar um dos 4 meios acima, deverá enviar o comprovante de pagamento para o e-mail
						<a href="mailto:sermacon2024@gmail.com">sermacon2024@gmail.com</a>.

						Caso tenha alguma dúvida, favor ligar para o GOB-ES no telefone <a href="tel:+5527995056049">27
							99505-6049 - Leila (Financeiro)</a> ou <a href="+5527997732095">27 99773-2095 - Key
							(Secretaria)</a>
					</p>
				</div>
			</div>
			<br /><br /><br />
			<div v-if="informacoes_pos_inscricao != false" class="col-12">
				<div class="mx-auto d-block text-center">
					<div class="col-sm-6 mx-auto d-block text-right">
						<router-link :to="{ name: 'inscricao' }" class="btn  btn-lg btn-success">
							&#9998; FAZER NOVA INSCRIÇÃO
						</router-link>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-12">
					<div class="checkout">
						<div>
							<link rel="stylesheet"
								href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css">
							<b>Formas de pagamento:</b>
							<div class="formaspagamento">
								<img src="../assets/card.png">
								<img src="../assets/pix2.png" style="background: #fff; padding: 4px;">
								<img src="../assets/din.png">
							</div>
							<!-- <p style="text-align:right">Até 6X sem juros.<br>A taxa praticada é de 0,99%</p> -->

						</div>
						<div class="title">Valor total da inscrição:</div>
						<div class="preco">{{ valor_total }}</div>

						<div class="button-confirmar p-5">
							<button v-if="submit_enabled" v-on:click="envia_inscricao();"
								class="btn btn-success"><b>CONFIRMAR INSCRIÇÃO</b></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src

import HelloWorld from "@/components/HelloWorld.vue";
import axios from 'axios';

export default {
	name: "inscricao",
	data: function () {
		return {
			mais_de_um_quarto: false,
			quarto_selected: "",
			pessoa_sample: {
				nome: "",
				idade: "",
				cpf: "",
				parentesco: "",
				tamanho_camisa: ""
			},
			valor_total: 0,
			valor_total_number: 0,
			quartos_selected: [],
			alert_show: false,
			alert_message: "",
			alert_status: "1233",
			tudo_preenchido: false,
			nomeparticipante: '',
			emailvalido: true,
			submit_enabled: true,
			informacoes_pos_inscricao: false,
			blocos: [
				{
					id: "bloco_unico",
					nome: "Bloco único",
					casal: 230,
					solteiro_double: 98,
					casal_pne_double: 16,
				},
				/*{
					id: "bloco_1",
					nome: "Bloco 1",
					casal: 230,
					solteiro_double: 98,
					casal_pne_double: 16,
				},*/

			],
			quartos: [
				{
					id: "casal",
					title: "Casal",
					desc: "01 cama de casal = R$ 1.600,00 | camas extras máximo 02, cobrado à parte",
					cama_principal: 2,
					cama_extra: 2,
					valor_principal: 1600,
					valor_single: 1250,
					valor_cama_extra: 800,
					valor_cama_extra_crianca: 480,
					subtotal: 0,
					capacidade: {
						principal: 230,
						extra: 230
					},
					bloco_escolhido: "",
					pessoas: []
				},
				{
					id: "solteiro_double",
					title: "DUPLO Solteiro",
					desc: "02 camas solteiro = R$ 1.600,00 | camas extras máximo 02, cobrado à parte",
					cama_principal: 2,
					cama_extra: 2,
					valor_principal: 1600,
					valor_single: 1250,
					valor_cama_extra: 800,
					valor_cama_extra_crianca: 480,
					subtotal: 0,
					capacidade: {
						principal: 98,
						extra: 98
					},
					bloco_escolhido: "",
					pessoas: []
				},
				{
					id: "casal_pne_double",
					title: "Casal PNE  - ESPECIAL",
					desc: "01 cama casal",
					cama_principal: 2,
					cama_extra: 0,
					valor_principal: 1600,
					valor_single: 1250,
					valor_cama_extra: 800,
					valor_cama_extra_crianca: 480,
					subtotal: 0,

					capacidade: {
						principal: 16,
						extra: 16
					},
					bloco_escolhido: "",
					pessoas: []
				},


			],
			cadastro_macon: true,
			campos: [
				{
					id: "participante",
					label: "Participante",
					valor: "",
					mask: ""
				},
				{
					id: "cim",
					label: "CIM",
					valor: "",
					mask: ""
				},
				{
					id: "cpf",
					label: "CPF",
					valor: "",
					mask: "###.###.###-##"
				},
				{
					id: "telefone",
					label: "Telefone",
					valor: "",
					mask: "(##) ####-#####"
				},
				{
					id: "email",
					label: "E-mail",
					valor: "",
					mask: ""
				},

				{
					id: "endereco_cep",
					label: "CEP",
					valor: "",
					mask: "##.###-###"
				},
				{
					id: "endereco_rua",
					label: "Rua",
					valor: "",
					mask: ""
				},
				{
					id: "endereco_numero",
					label: "Número",
					valor: "",
					mask: ""
				},
				{
					id: "endereco_bairro",
					label: "Bairro",
					valor: "",
					mask: ""
				},

				{
					id: "endereco_cidade",
					label: "Cidade",
					valor: "",
					mask: ""
				},
				{
					id: "endereco_uf",
					label: "UF",
					valor: "",
					mask: ""
				},
				{
					id: "loja",
					label: "Loja",
					valor: "",
					mask: "",
					combo: true,
					search_field: true,
					search_field_active: false,

					options: [
						'ACÁCIA DE GUARAPARI',
						'ACACIA FORMOSA DE SANTA CRUZ',
						'ACÁCIA VILAVELHENSE',
						'ACADEMICA CONSTRUTORES DO FUTURO',
						'ALFERES TIRADENTES',
						'AMOR E JUSTIÇA II',
						'ANÍBAL FREIRE',
						'ANNITA',
						'ANTHÁRIO FILHO',
						'AVIDES FRAGA',
						'BENEFICÊNCIA SETE',
						'CARIDADE E ESPERANÇA',
						'CARLOS ALBERTO SWERTS ESTEVES',
						'CAVALEIROS DA JUSTIÇA',
						'CAVALEIROS DE AÇO DO ESPIRITO SANTO',
						'CAVALEIROS TEMPLÁRIOS',
						'CIDADE DE ANCHIETA',
						'CONCÓRDIA FLORIANENSE',
						'CUORE ITALIANO III',
						'DELTA MAÇÔNICA FILHOS DE LUZ E VIRTUDE',
						'DEOCLÉCIO RAMOS',
						'DESEMBARGADOR JOSÉ DE OLIVEIRA ROZA',
						'DOMINGOS MARTINS',
						'DR. AMÉRICO DE OLIVEIRA',
						'DR. IDIÁLVARO DESSAUNE',
						'DR. WALLACE VIEIRA BORGES',
						'ESTRELA DE CAMBURI',
						'ESTRELA DE IBIRAÇU',
						'ESTRELA DE GURIRI',
						'ESTRELA DE MANGUINHOS',
						'ESTRELA DE SÃO GABRIEL',
						'ETERNO APRENDIZ',
						'FAUSTO CARDOSO TOSCANO',
						'FÉLIZ ANTÔNIO ZANON',
						'FENELON BARBOSA',
						'FRATERNIDADE ADONHIRAMITA',
						'FRATERNIDADE DO UNIVERSO',
						'FRATERNIDADE E LUZ',
						'FRATERNIDADE GUANDUENSE',
						'FRATERNIDADE ICONHENSE',
						'FRATERNIDADE ITARANENSE',
						'FRATERNIDADE UNIVERSAL V',
						'GRANDE MESTRE ALBERTO MANSUR II',
						'GRÃO-MESTRE FRANCISCO MURILO PINTO',
						'GUARDIÃ DA DEMOCRACIA',
						'HUMILDADE E FRATERNIDADE',
						'IIR ALCEBÍADES D’ÁVILA E JAYME BULHÕES',
						'IR AYLTON DE MENEZES',
						'IR MANOEL ALVES CORRÊA',
						'IR OSWALDO ALBERNAZ',
						'IVAN NEIVA NEVES',
						'IZAÍAS DE OLIVEIRA FREITAS',
						'JOACY PALHANO',
						'JOHN HARRIS',
						'JOSÉ BAHIA',
						'JOSÉ CUPERTINO',
						'JOSÉ DE LIMA',
						'JUSTIÇA E CARIDADE',
						'JUVENTUDE E CIÊNCIA PROF. JOSÉ RIBEIRO FILHO',
						'LIBERDADE E LUZ',
						'LINHARES UNIDO',
						'LUZ DA JUPARANÃ',
						'LUZ DO PLANALTO',
						'MAGALHÃES E ESPÍNDULA',
						'MARCOS DAHER',
						'MARIO RODRIGUES FREITAS',
						'MENSAGEIROS DA LUZ',
						'MILTON CADE',
						'MONTEIRO LOBATO',
						'NETO CAIADO',
						'NILO PEÇANHA',
						'OBREIROS DO VALE DO ITABAPOANA',
						'ORDEM E PERSEVERANÇA',
						'OSWALDO COUTO RODRIGUES',
						'PRAIA DA COSTA',
						'PRESIDENTE KENNEDY',
						'PRESIDENTE ROOSEVELT',
						'PROFESSOR ALBERTO STANGE',
						'PROFESSOR HERMÍNIO BLACKMAN',
						'14 DE JULHO',
						'RUFINO MANOEL DE OLIVEIRA',
						'SANTÍSSIMA TRINDADE - ESPÍRITO SANTO',
						'SÃO JOÃO BATISTA',
						'SERMÃO DA MONTANHA',
						'TEMPLÁRIOS DO APOCALIPSE',
						'13 DE MAIO',
						'UNIÃO CAPIXABA',
						'UNIÃO E FRATERNIDADE',
						'UNIÃO E IGUALDADE',
						'UNIÃO E PROGRESSO',
						'UNIÃO E RAZÃO',
						'UNIÃO, FORÇA E SABEDORIA',
						'UNIÃO, VIGILÂNCIA E PERSEVERANÇA',
						'UNIV. E BENEF. PROF. ALFREDO PACHECO BARROCA',
						'UNIV. IR PROF. CARLOS MAGNO RODRIGUES BRAVO',
						'VALE DO ITAPEMIRIM',
						'VICTÓRIA',
						'22 DE AGOSTO',
						'VIVER EM UNIÃO',
						'WILLIAM NEMER',
						'OUTRA',
					]
				},

				// {
				// 	id: "loja_numero",
				// 	label: "Número",
				// 	valor: "",
				// 	mask: ""
				// },
				{
					id: "oriente",
					label: "Oriente",
					valor: "",
					mask: ""
				},
				{
					id: "observacao",
					label: "Alguma observação",
					valor: "",
					mask: ""
				},
				// {
				// 	id: "tamanho_camisa",
				// 	label: "Tamanho da Camisa",
				// 	valor: "",
				// 	mask: "",
				// }


			],

		};


	},
	components: {
		HelloWorld
	},
	methods: {
		reloadPage() {
			window.location.reload();
		},
		adiciona_pessoa: function (array) {
			array.push(JSON.parse(JSON.stringify(this.pessoa_sample)));
		},
		remove_pessoa: function (array, pessoa) {
			array.splice(pessoa);
		},
		remove_quarto: function (quarto) {
			this.quartos_selected.splice(quarto);
		},
		adiciona_quarto: function () {
			if (this.quarto_selected != undefined) {
				this.mais_de_um_quarto = true;
				this.quartos_selected.push(
					JSON.parse(
						JSON.stringify(this.quartos[this.quarto_selected])
					)
				);
				this.quarto_selected = 0;
			}
		},

		calcula_valor_final: function () {
			let valor = 0;
			this.quartos_selected.forEach(function (quarto) {
				if (quarto.pessoas.length > 1) {
					//valor de cada single
					if (quarto.pessoas.length < quarto.cama_principal) {

						quarto.pessoas.forEach(function (pessoa, index_pessoa) {

							//para o caso da cama de casal + solteiro 
							if (quarto.pessoas.length == 2) {
								valor = 1600; // valor duplo
							}

							else {
								valor += quarto.valor_single;
							}


						});
					}
					else if (quarto.pessoas.length >= quarto.cama_principal) {
						valor += quarto.valor_principal;

					}
					//adiciona cama extra
					if (quarto.pessoas.length > quarto.cama_principal) {
						quarto.pessoas.forEach(function (pessoa, index_pessoa) {
							if (index_pessoa >= quarto.cama_principal) {
								if (pessoa.idade > 10) {
									valor += quarto.valor_cama_extra;
								} else if (pessoa.idade > 5) {
									valor += quarto.valor_cama_extra_crianca;
								}
							}

						});
					}

				}
				//single
				else if (quarto.pessoas.length == 1) {
					valor += quarto.valor_single;
				}

			});
			this.valor_total_number = valor;
			this.valor_total = valor.toLocaleString("pt-br", {
				style: "currency",
				currency: "BRL"
			});
		},
		show_alert: function (status, text) {
			this.alert_message = text;
			this.alert_status = status;
			this.alert_show = true;
		},
		is_mandatory: function (index) {
			switch (index) {
				case "loja":
				case "oriente":
				case "potencia":
					return true;
					break;
				case "observacao":
				case "cim":
				case "endereco_numero":
					return false;
					break;
				default:
					return true;
					break;
			}
		},
		envia_inscricao: function () {
			this.tudo_preenchido = true;
			this.quartos_selected.forEach((quarto) => {
				quarto.pessoas.forEach((pessoa) => {
					pessoa.nome = pessoa.nome.replace(/'/g, "");
				});
			});
			let dados = {
				dados_pessoais: this.campos,
				quartos: this.quartos_selected,
				valor_total: this.valor_total_number
			};

			if (this.campos[0].valor) {
				//his.tudo_preenchido = false;
				//alert('Digite seu nome');
				//return;t
				this.nomeparticipante = this.campos[0].valor.replace(/'/g, "");
			}
			let camposvazios = [];
			this.campos.forEach((campo, index) => {
				if (campo.valor === "") {
					if (this.is_mandatory(campo.id)) {
						this.tudo_preenchido = false;
						camposvazios.push(campo.label); // Use push to add the label to the array
					}
				}

				else {
					if (this.campos[4].valor) {
						//alert(this.campos[4].valor + this.campos[4].label);

					}
					else {
						campo.valor = campo.valor.toUpperCase()
					}
				}
			});

			if (camposvazios.length > 0) {
				alert('PREENCHA O(S) SEGUINTE9(S) CAMPO(S) OBRIGATÓRIO(S): \n  -->' + camposvazios.join('\n -->'));
			}

			//mask email
			if (this.campos[4].id === 'email') {
				if (!this.isValidEmail(this.campos[4].valor)) {
					alert("E-MAIL INVÁLIDO");
					this.emailvalido = false;
					return
				}
				else {
					this.emailvalido = true;
				}
			}
			;
			const _self = this;

			// alert({ dados: dados });
			if (this.tudo_preenchido != false && this.emailvalido != false) {
				this.$http
					.post(_self.$store.state.api_url + "/admin/inscricao.php", {
						dados: dados
					})
					.then(response => {

						this.show_alert(
							"info",
							"Aguarde um momento, estamos enviando sua reserva."
						);
						console.log(response.data.error);
						if (response.data.status) {
							this.submit_enabled = false;
							this.show_alert(
								"success",
								"Sua de reserva de inscrição foi realizada com sucesso. "
							);
							_self.informacoes_pos_inscricao = this.campos[4].valor;
							this.$router.push("/confirma-inscricao/" + this.campos[4].valor);
						} else {
							this.show_alert(
								"danger",
								"Ocorreu um erro, verifique se seu E-MAIL está correto. " + response.data.error
							);
							this.submit_enabled = true;
						}
					});
			}
		},
		get_blocos(quarto_id) {
			let returned_blocos = [];
			this.blocos.forEach(bloco => {
				if (bloco[quarto_id] > 0) {
					returned_blocos.push({
						nome: bloco.nome,
						id: bloco.id
					});
				}
			});
			return returned_blocos;
		},

		search_field_open(campo) {
			if (campo.search_field) {
				campo.search_field_active = true;
			}
		},
		search_field_close(campo) {
			setTimeout(() => {
				if (campo.search_field) {
					campo.search_field_active = false;
				}
			}, 100);
		},
		filter_loja(valor, loja) {
			return (this.filter_string(loja).indexOf(this.filter_string(valor)) >= 0);
		},
		filter_string(string) {
			return this.retira_acentos(string).toUpperCase();
		},
		retira_acentos(str) {
			let i;
			let troca;
			let a;
			const com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
			const sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
			let novastr = "";
			for (let i = 0; i < str.length; i++) {
				troca = false;
				for (a = 0; a < com_acento.length; a++) {
					if (str.substr(i, 1) == com_acento.substr(a, 1)) {
						novastr += sem_acento.substr(a, 1);
						troca = true;
						break;
					}
				}
				if (troca == false) {
					novastr += str.substr(i, 1);
				}
			}
			return novastr;
		},

		async fetchAddress() {
			const enderecoCepCampo = this.campos.find(campo => campo.id === 'endereco_cep');
			if (enderecoCepCampo && enderecoCepCampo.valor.length === 8) {
				try {
					const response = await axios.get(
						`https://viacep.com.br/ws/${enderecoCepCampo.valor}/json/`
					);
					this.campos.find(campo => campo.id === 'endereco_cidade').valor = response.data.localidade.replace(/'/g, "").toUpperCase();
					this.campos.find(campo => campo.id === 'endereco_rua').valor = response.data.logradouro.replace(/'/g, "").toUpperCase();
					this.campos.find(campo => campo.id === 'endereco_bairro').valor = response.data.bairro.replace(/'/g, "").toUpperCase();
					this.campos.find(campo => campo.id === 'endereco_uf').valor = response.data.uf.toUpperCase();

				}
				catch (error) {
					console.error('Error fetching address:', error);
				}
			}
		},
		functionsInput(campo, value) {
			if (campo.id === 'endereco_cep') {
				this.fetchAddress();
			}
			if (campo.id === 'participante' || campo.id === 'oriente' || campo.id === 'endereco_bairro' || campo.id === 'endereco_rua' || campo.id === 'endereco_cidade' || campo.id === 'observacao') {
				campo.valor = event.target.value.replace(/'/g, "").toUpperCase();
				if (campo.id == 'participante') {
					this.nomeparticipante = campo.valor.replace(/'/g, "");
				}
			} else {
				console.log(campo);

			}
		},
		functionsInputUpper(value) {

		},
		isValidEmail(email) {
			const emailRegex = /^([a-zA-Z0-9._-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})$/;
			return emailRegex.test(email);
		},
	},
	computed: {},
	mounted() {
		setInterval(() => {
			this.calcula_valor_final();
		}, 300);
	},
	beforeMount() {

		const _self = this;
		_self.$http
			.get(
				_self.$store.state.api_url +
				"/admin/api/sermacon/get_quartos_blocos.php"
			)
			.then(response => {
				_self.blocos = response.data;
			});
	}

};
</script>
<style lang="scss" scoped>
.alert {
	margin-bottom: 0;
}

.box-pagamento-cartao {
	background: #94ff94;
	border: 1px solid #25b225;
	padding: 1rem 0;
	border-radius: 8px;
	margin: 1rem 0;
	margin-bottom: 20px;
}


.dados_pagamento {
	background: #94ff94;
	border: 1px solid #25b225;
	border-radius: 8px;
	padding: 10px;
	text-align: left !important;
}

.dados_pagamento hr {
	border-top: 1px solid #2c3e50;
}

.dados_pagamento h2,
.dados_pagamento h3 {
	margin-top: 0;
	font-weight: bold;
}

.dados_pagamento span {
	background: #25b225;
	padding: 4px;
	border-radius: 8px;
	margin: 4px;
	color: white;
}

.dados_pagamento a {
	text-decoration: none;
	font-weight: bold;
}

.button-confirmar .btn-success {
	padding: 7px;
	font-size: 17px;
	background: #25b225;
	text-shadow: 0px 0px 3px #ffffffa8;
}

.alert-warning {
	color: #ffffff;
	background-color: #f5ad25;
	box-shadow: 1px 1px 18px -6px #00000050;
}

.formaspagamento {
	display: flex;
	-webkit-box-pack: center;
	color: #3a3a3a;
	font-size: 13px;
	text-transform: uppercase;
	-webkit-box-align: center;
	align-items: center;
	justify-content: flex-end;
}

.formaspagamento img {
	height: 25px;
	-o-object-fit: contain;
	object-fit: contain;
	margin: 5px;
	box-shadow: 1px 1px 5px -1px #00000080;
	border-radius: 5px;
}

.informacoes_pos_inscricao {
	background-color: #e03d3d;
	padding: 18px;
	font-size: 18px;
	color: #ffffff;
	width: 80%;
	border-radius: 5px;
}

.aviso-quarto-inscrito {
	font-size: 18px;
	color: #d46200;
}

.imagem {
	display: flex;
	justify-content: center;
}

.content {
	font-family: "Open Sans", sans-serif;
	width: 70%;
	position: relative;
	display: inline-block;
	background-color: white;
	padding: 15px;
	box-shadow: 1px 1px 20px -9px #00000080;

	.field {
		text-align: left;
		padding-bottom: 15px;

		label {
			margin-bottom: 0px;
		}
	}
}

.title {
	font-size: 18px;
	margin-bottom: 20px;
	margin-top: 15px;
	font-weight: bold;
}

.search_field {
	position: relative;
	height: 1px;
	width: 100%;

	ul {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 10;
		margin: 0;
		padding: 0;
		background-color: #f8f8f8;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
		max-height: 200px;
		overflow-y: scroll;

		li {
			list-style: none;
			padding: 5px 2px;
			cursor: pointer;

			&:hover {
				background-color: #dadada;
			}
		}
	}
}

.alert {
	font-size: 18px;
}

.tabela-precos {
	.title {
		font-size: 18px;
		margin-bottom: 20px;
		margin-top: 15px;
		font-weight: bold;
	}
}

.quarto-select {
	display: flex;

	select {
		margin-right: 10px;
	}
}

.quartos-content {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 15px;
	padding-right: 15px;

	.item {
		position: relative;
		padding: 10px;
		background-color: #f8f8f8;
		margin-bottom: 15px;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);

		.remove-quarto {
			position: absolute;
			top: 0;
			right: 0;
			padding: 15px;
		}

		.info {
			.title {
				text-align: left;
				font-size: 18px;
				font-weight: bold;
				color: #333;
			}

			.descricao {
				text-align: left;
				font-size: 16px;
			}
		}

		.pessoas {
			.title {
				font-size: 18px;
				margin-top: 15px;
				margin-bottom: 10px;
				text-align: left;
				font-weight: bold;
			}
		}

		.pessoas-content {
			.pessoa {
				display: flex;
				align-items: flex-end;
				justify-content: space-between;

				&.cama-extra {
					padding-top: 40px;
					padding-left: 10px;
					padding-right: 15px;
					position: relative;
					background-color: #94ff94;
					margin-bottom: 10px;

					&::after {
						content: "CAMA EXTRA \a (o valor da cama extra varia de acordo com a idade)\a ";
						text-align: left;
						line-height: 1;
						white-space: pre;
						position: absolute;
						top: 5px;
						left: 5px;
						font-weight: bold;
						font-size: 16px;
						color: #136d13;
					}
				}

				.field {
					margin-left: 5px;
				}
			}
		}

		.btn-content {
			display: flex;
			justify-content: left;
		}

		.subtotal {
			text-align: right;

			.title {
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 0px;
				margin-top: 10px;
			}

			.valor {
				font-size: 20px;
				color: green;
				font-weight: bold;
			}
		}
	}
}

.escolhe_bloco {
	margin-top: 5px;
}

.checkout {
	margin-top: 50px;
	text-align: right;

	.title {
		font-weight: bold;
	}

	.preco {
		text-align: right;
		font-size: 25px;
		font-weight: bold;
		color: green;
		margin-bottom: 15px;
	}

	button {
		width: 100%;
	}
}

table {
	text-align: left;
}

.mapa-sesc {
	max-width: 100%;
}

label span {
	color: red;
}

@media (max-width:768px) {
	body {
		padding-top: 0px !important;
	}

	.content {
		width: 100%;
	}

	.alert {
		font-size: 11px;
	}

	.pessoa {
		flex-wrap: wrap;
	}

	.pessoa .field {
		width: 100%;
	}

	.escolhe_bloco .pessoa .field {
		width: 100%;
	}
}

.alerta-conclusao {
	display: flex;
	justify-content: center;
}
</style>
