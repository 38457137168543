<template>
	<div class="home">
		<div class="content">
			<div class="imagem">
				<img
					src="http://sermacon.arcoinformatica.com.br/assets/sermacon.jpg"
					alt
					class="img-responsive"
				/>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import Apartamento from "@/models/Apartamento.js";
import Casal from "@/models/Apartamento.js";

export default {
	name: "home",
	data: function() {
		return {};
	},

};
</script>	
<style lang="scss" scoped>
.imagem {
	display: flex;
	justify-content: center;
}
.content {
	font-family: "Open Sans", sans-serif;
	width: 70%;
	position: relative;
	display: inline-block;
	background-color: white;
	padding: 15px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);

	.field {
		text-align: left;
		padding-bottom: 15px;

		label {
			margin-bottom: 0px;
		}
	}
}
</style>
