<template>
    <div class="home inscricao">
        <div class="content">
            <div class="imagem">
                <img src="../assets/LOGO-SERMACCON-2024.png" alt class="img-responsive" width="40%" />
            </div>
        </div>
        <div class="form">
            <p>Coloque o e-mail que você usou na inscrição e o CPF que você usou na inscrição.</p>
            <form action="" id="form_login">
                <input class="form-control" type="text" placeholder="E-mail do hóspede principal" name="email_login" id="email_login" v-model="email_login"/>
                <input class="form-control" type="text" placeholder="CPF do hospede principal" name="cpf_login" id="cpf_login" v-model="cpf_login"/>

                <button class="btn btn-warning" v-on:click.prevent="login">Ir para pagamento</button>
            </form>
        </div>
        <div class="modal_lista_inscricoes" v-if="show_more_one_subscription">
            <button class="btn btn-danger mb-3" v-on:click="close_modal">Fechar</button>
            <p>Verifique o valor e a data que foi feita a inscrição que deseja pagar.</p>
            <ul>
                <li v-for="inscricao in subscribe_users" :key="inscricao.id">
                    <a class="btn btn-warning" target="_blank" :href="'https://painel.sermacon.gob-es.org.br/admin/checkout/checkout.php?inscricao='+inscricao.id">{{'R$ '+inscricao.valor_total+' - '+inscricao.time_cadastro }}</a>
                </li> 
            </ul>
        </div>
    </div>
</template>

<style scoped>
.modal_lista_inscricoes ul{
    list-style: none;
    padding: 0;
}

.modal_lista_inscricoes ul li a{
    background: #f0ad4e;
    border-radius: 8px;
    padding: 10px;
    display: block;
    margin: .5rem auto;
    text-decoration: none;
    color: #fff;
}

.modal_lista_inscricoes{
    background: #94ff94;
    border:1px solid #25b225;
    border-radius: 8px;
    max-width: 500px;
    min-height: 300px;
    overflow: auto;
    position:absolute;
    top:50%;
    right: 50%;
    transform: translate(50%,-50%);
    padding: 1rem;
}
.form form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:1rem;
    background: #7dbde6;
    max-width: 300px;
    margin: 0 auto;
    padding: 3rem 1rem;
    border: 1px solid #317bc1;
    border-radius: 8px;
}

.home.inscricao .content .imagem img {
    margin: 0 auto;
}

.new-subscription {
    margin-block: 50px;
    text-align: center;
    margin: 8px auto;
}

.informacoes_pos_inscricao {
    background-color: #e03d3d;
    padding: 18px;
    font-size: 18px;
    color: #ffffff;
    width: 80%;
    border-radius: 5px;
    margin: 0 auto;
}
</style>

<script>
export default {
    name: 'PagamentoLogin',
    data() {
        return {
            email_login: this.$route.params.email ?? '',
            cpf_login: '',
            show_more_one_subscription: false,
            subscribe_users:[]
        }
    },
    methods: {
        close_modal(){
            this.show_more_one_subscription = false;
        },
        login() {
            if(this.email_login == '' || this.cpf_login == ''){
                alert('Preencha todos os campos');
                return;
            }

            const formData = new FormData();
            formData.append('email', this.email_login);
            formData.append('cpf', this.cpf_login);
            
            // const url = "http://localhost:8000/auth_pagamento.php";
            const url = "https://painel.sermacon.gob-es.org.br/admin/api/sermacon/auth_pagamento.php";
            // const url = "http://painelsermacon.localhost.com.br/admin/api/sermacon/auth_pagamento.php";

            fetch(url,{
                method: "POST",
                body: formData,
                mode: 'cors'
            }).then(response => {
                return response.json();
            }).then(data =>{
                if(data.length > 1){
                    this.subscribe_users = data;
                    data.forEach(item=>{
                        this.show_more_one_subscription = true;
                    });
                }else{
                    if(data[0].status){
                        let new_tab = window.open();
                        this.email_login = '';
                        this.cpf_login = '';
                        // new_tab.location.href = "http://painelsermacon.localhost.com.br/admin/checkout/checkout.php?inscricao="+data[0].id;
                        new_tab.location.href = "https://painel.sermacon.gob-es.org.br/admin/checkout/checkout.php?inscricao="+data[0].id;
                    }else{
                        alert(data[0].error+"\n");
                    }
                }
            });
        }
    }
}
</script>
