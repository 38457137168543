<style>
    body{
        background-size: auto;
    }
    .btn{
        border-bottom: 3px solid #00000030;
        border-right: 4px solid #00000030;
    }
        .sermacon {
            width: 100%;
            position: absolute;
            top: 50px;
            right: 50px;
        }
    	.sermacon-img{
            max-width: 55%;
        }
        .contagem {
            position: absolute;
            right: 0;
        }
        
        @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.6; }
            100% { opacity: 1; }
        }
       .sermacon h2 {
            font-size: 50px;
            font-family: system-ui;
            margin: 0;
            -webkit-animation: blink 0.8s infinite;
            animation: blink 0.8s infinite;
            display: -webkit-box;
            display: -ms-flexbox;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
        }
         .contagem div {
            border-radius: 5px 5px 0 0;
            width: 126px;
            height: 150px;
            color: #2c64ad;
            border: 1px solid;
            background: #f7f7f7;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .sermacon p {
			margin: 0;
			font-size: 27px;
			font-weight: 600;
			font-family: system-ui;
			color: #2C64AE;
			text-align: center;
			text-transform: uppercase;
			animation: blink 0.8s infinite;

		}
        .sermacon span {
            display: inline-block;
            width: 100%;
            background: #3d70b3;
            color: #fff;
            font-size: 97%;
            font-weight: 600;
            font-family: system-ui;
            text-transform: uppercase;
            padding: 1.5% 17%;
            position: relative;
            z-index: 123456;
            border-top: 5px dotted;
            border-radius: 2px;
        }
        @media screen and (max-width: 600px){
             .sermacon {
                top: 0;
                right: 0;
            }
            h1 {
                font-size: 26px;
            }
            .sermacon-img {
                max-width: 72%;
            }
            .col-sm-6.mx-auto.d-block {
                text-align: center;
                margin: 10px;
            }
            .contagem div {
                width: 90px;
                height: 100px;
            }
            .sermacon h2 {
                font-size: 30px;
            }
            .sermacon p {
			    font-size: 18px;
            }
        }

        @media (min-width: 900px){
            .botoes{
                display:inline-flex !important;
            }
        }
        .texto{
            padding: 0px 20%;
            color: #ff5f00;
            font-weight: bold;
        }
        
</style>

<template>
	<div class="home">
		<div class="content text-center">


            <div class="sermacon">
                <div class="contagem"> 
                    <div>
                        <span>Restam:</span>
                        <h2 id="countdown">{{ countdown }}</h2>
                         <p>DIAS</p>

                    </div>
                </div>
            </div>

        	<h1>BEM VINDO! </h1>
			<div class="imagem mb-5">
				<img src="../assets/LOGO-SERMACCON-2024.png" alt class="mx-auto d-block sermacon-img">
			</div>
			<div>
                <div class="col-sm-12">
                    <div class="texto">
                        <p>Até presente momento, os pagamentos estão sendo realizados através de PIX, Transferência Bancária, Dinheiro e Cheque (parcelamento em até 3X).<br>
                        Em breve estaremos disponibilizando também pagamento em Cartão de Crédito com parcelamento!</p>
                    </div>
                </div>
				<div class="col-sm-12">
					<div class="botoes">
                        <div class="col-sm-4 mx-auto d-block text-center" style="margin-top: 15px;"><router-link :to="{ name: 'inscricao' }"  class="btn  btn-lg btn-success">&#9998; INSCREVER-SE</router-link></div>
                        <div class="col-sm-4 mx-auto d-block text-center" style="margin-top: 15px;"><a :href="'/programacao.pdf?t='+currentTime" class="btn btn-lg btn-info" data-toggle="button" target="_blank" >&#8681; PROGRAMAÇÃO</a></div>
                        <div class="col-sm-4 mx-auto d-block text-center" style="margin-top: 15px;"><a href="/Mapa-de-CTSLPF.pdf" class="btn btn-lg btn-info" data-toggle="button" target="_blank" >&#9432; CONHECER LOCAL</a></div>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
 export default {
        data: function() {
		    return {
                currentTime: Math.floor(Date.now() / 1000),
                targetDate: new Date('2024-08-31T00:00:00'),
                countdown: 0
            }
        },
        mounted() {
         // Update countdown on mount
            this.updateCountdown();
             //alert(window.location.href);

            // Update countdown every second
            this.timer = setInterval(() => {
                this.updateCountdown();
            }, 1000);
        },
        methods: {
            updateCountdown() {
                const currentDate = new Date();
                const timeDiff = Math.floor((this.targetDate - currentDate) / (1000 * 60 * 60 * 24));
                this.countdown = timeDiff;
            }
        },
        beforeDestroy() {
            clearInterval(this.timer);
        }
    };
</script>