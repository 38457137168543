import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //api_url: "http://local.sermacon.com.br"
    api_url: "https://painel.sermacon.gob-es.org.br"
    // api_url: "https://clonesermacon.arcohosting.com.br/admin/"
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
